<template>
    <div>
        <div class="modal fade" id="newsletterModal" tabindex="-1" aria-labelledby="newsletterModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header switch-bg-blue">
                <h5 class="modal-title" id="newsletterModalLabel">Join our mailing list</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <NewsletterForm />
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="contactModal" tabindex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header switch-bg-blue">
                <h5 class="modal-title" id="contactModalLabel">Send us a message</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="downloadModal" tabindex="-1" aria-labelledby="downloadModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header switch-bg-blue">
                <h5 class="modal-title" id="downloadModalLabel">Download our buyers packs</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <DownloadForm />
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="downloadModalDrakenzicht" tabindex="-1" aria-labelledby="downloadModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header switch-bg-blue">
                <h5 class="modal-title" id="downloadModalLabel">Download our buyers packs</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <DownloadFormDrakenzicht />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row p-4">
          <div class="col-md-3">
            <h2 class="switch-white"><strong>CONTACT US</strong></h2>
            <a class="switch-white" href="tel:+27832581212">+27 83 258 1212</a><br/>
            <a class="switch-white" href="mailto:admin.valdevie@everitt.co.za">admin.valdevie@everitt.co.za</a>
          </div>
          <div class="col-md-3 text-end">
            <h2 class="switch-white"><strong>STAY IN TOUCH</strong></h2>
            <a class="switch-white" type="button" data-bs-toggle="modal" data-bs-target="#newsletterModal">Join our mailing list</a><br/>
            <a class="switch-white" type="button" data-bs-toggle="modal" data-bs-target="#contactModal">Send us a message</a>
          </div>
        </div> -->        
        <footer>
            <div class="container-fluid py-3">
                <div class="row">
                  <div class="col-md-12 text-center pb-5 mb-4">
                    <p class="text-dark">Copyright &copy; 2024 - Chas Everitt Properties<br/><a target="_blank" class="text-dark" href="https://www.chaseveritt.co.za/disclaimer-policy-website/">Terms, conditions and disclaimer</a></p>
                  </div>
                </div>
            </div>
        </footer>

    </div>
</template>
<script>

import NewsletterForm from '@/components/NewsletterForm.vue'
import ContactForm from '@/components/ContactForm.vue'
import DownloadForm from '@/components/DownloadForm.vue'
import DownloadFormDrakenzicht from '@/components/DownloadFormDrakenzicht.vue'

export default {
  components: {
    NewsletterForm, ContactForm, DownloadForm, DownloadFormDrakenzicht
  },
  mounted() {

  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {
    toggleType(type) {
      if (type === 'buy' || type === 'rent') {
        this.$store.commit('setFilter', { filter: 'buy', value: type === 'buy' });
        this.$store.commit('setFilter', { filter: 'rent', value: type === 'rent' });
        this.$store.commit('setMaxPrice', this.maxPrice);
        this.selectedType = this.selectedType === type ? null : type;
      }
      console.log(this.$store.state.search)
    },
    toggleLocation(location) {
      console.log(location)
    },
  }
}
</script>
<style scoped>
.burger-menu {
  width: 30px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.bar {
  width: 100%;
  height: 4px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.3s;
}

.burger-menu:hover .bar:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
}

.burger-menu:hover .bar:nth-child(2) {
  opacity: 0;
}

.burger-menu:hover .bar:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}
</style>